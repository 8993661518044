<template>
  <div>

    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>
  
    <v-form v-else v-model="valid" ref="formNota" lazy-validation>
      <v-container fluid>
        <v-widget title="Nota Fiscal de Transferência">
          <div slot="widget-content">

            <v-tabs v-model="abaAtiva">
              <v-tab :key="1">Cabeçalho da Nota</v-tab>
              <v-tab :key="2">Itens da Nota</v-tab>
              <v-tab :key="3">Totais</v-tab>
            </v-tabs>

            <v-tabs-items v-model="abaAtiva">
              <v-divider></v-divider>

              <v-tab-item :key="1" eager>
                <v-widget title="Cabeçalho da Nota" :temCampoObrigatorio="true" class="mt-4">
                  <div slot="widget-content">
                    <v-row dense>
                      <v-col cols="12" sm="2">
                        <v-text-field label="Série *" v-model="nota.serie" v-mask="'###'" :rules="[() => !!nota.serie || 'Série obrigatória', v => (v && v.length === 3) || 'Formato Inválido, ex: 001']" required data-cy="serie"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <campo-data label="Data de Emissão *" v-model="nota.data_emissao"></campo-data>
                      </v-col>
                      <v-col cols="12" sm="7">
                        <v-autocomplete label="Filial de Destino" :filter="buscarFilial" item-value="_id" item-text="razao_nome" :items="filiais" v-model="filialSelecionada" @change="definirFilial" :disabled="carregandoFiliais"></v-autocomplete>
                      </v-col>
                    </v-row>
                  </div>
                </v-widget>
              </v-tab-item>

              <v-tab-item :key="2" eager>
                <v-widget title="Lançamento dos Itens da Nota" :temCampoObrigatorio="true" class="mt-4">
                  <div slot="widget-content">
                    <v-row dense>
                      <v-col cols="12" sm="2">
                        <buscar-produto-deposito v-model="codigoProduto" :cnpjFilial="cliente.cnpj_cpf" ref="codigoProduto" @produtoSelecionado="produtoSelecionado"></buscar-produto-deposito>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field label="Descrição" v-model="produto.descricao" readonly ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="1">
                        <v-text-field label="CFOP *" v-model="produto.cfop" @keyup.enter="$refs.quantidadeProduto.focus()"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-text-field ref="quantidadeProduto" label="Quant." v-model="quantidade" @focus="onFocus" @input="calcularSubtotal" @keyup.enter="adicionarProduto()">
                          <template v-slot:append v-if="historicoProdutoPreco && historicoProdutoPreco.length > 0">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on" color="primary" dense @click="detalharEstoqueProdutoPreco">mdi-help-circle-outline</v-icon>
                              </template>
                              Detalhar Estoque do Produto
                            </v-tooltip>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="1">
                        <v-text-field label="Valor" ref="valorProduto" v-model="produto.valor" readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-text-field label="Total" ref="subtotalProduto" v-model="subtotal" readonly></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-widget>

                <v-widget title="Itens da Nota" class="mt-4">
                  <div slot="widget-content">
                    <v-row >
                      <v-col cols="12" sm="12">
                        <v-data-table :headers="headers" :items="produtosNota" hide-default-footer no-data-text="Nenhum Produto Adicionado">
                          <template v-slot:item="props">
                            <tr>
                              <td>{{ props.item.codigo }}</td>
                              <td>{{ props.item.sequencia }}</td>
                              <td>{{ props.item.descricao }}</td>
                              <td class="text-right">{{ props.item.valor }}</td>
                              <td class="text-right">{{ props.item.quantidade }}</td>
                              <td class="text-right">{{ props.item.subtotal }}</td>
                              <td class="text-center">
                                <v-icon @click="detalharProduto(props.item.sequencia - 1)">edit</v-icon>
                                <v-icon @click="removerProdutoNota(props.item)">delete</v-icon>
                              </td>
                            </tr>
                          </template>
                          <template v-slot:footer>
                            <v-row dense class="mt-4" justify="end">
                              <v-col cols="12" sm="2">
                                <v-text-field label="Total de Itens" v-model="totalItens" readonly></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="2">
                                <v-text-field label="Total de Produtos" v-model="totalProdutos" readonly></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="2">
                                <v-text-field label="Total da Venda" v-model="totalVenda" readonly></v-text-field>
                              </v-col>
                            </v-row>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </div>
                </v-widget>
              </v-tab-item>
              
              <v-tab-item :key="3" eager>
                <v-widget title="Total da Nota" class="mt-4">
                  <div slot="widget-content">
                    <v-row dense>
                      <v-col cols="12" sm="3">
                        <v-text-field label="Desconto" v-model="nota.desconto" :rules="validarValor" @blur="recalcularNota()" ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field label="Frete" v-model="nota.frete" :rules="validarValor" @blur="recalcularNota()" ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field label="Seguro" v-model="nota.seguro" :rules="validarValor" @blur="recalcularNota()"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field label="Outras Despesas" v-model="nota.outras_despesas" :rules="validarValor" @blur="recalcularNota()"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field label="Total Produtos" v-model="nota.total_produtos" readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field label="Total Tributos" v-model="nota.total_tributos" readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field label="Total da Nota" v-model="nota.total_nota" readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <v-textarea label="Informações Complementares" v-model="nota.info_complementar"></v-textarea>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <v-textarea label="Dados Adicionais" v-model="nota.dados_adicionais"></v-textarea>
                      </v-col>
                    </v-row>
                  </div>
                </v-widget>
              </v-tab-item>
              
            </v-tabs-items>
          </div>
          <template v-slot:widget-footer-action>
            <v-btn @click="cancelar" text>Cancelar</v-btn>
            <v-btn color="primary" :loading="carregando" @click="salvar()" :disabled="!valid" data-cy="salvar">{{textoBtnConfirmar}}</v-btn>
          </template>
        </v-widget>
      </v-container>
    </v-form>

    <dialog-detalhar-produto-nota ref="dialogDetalharProduto" @aplicarCfop="aplicarCfop" @aplicarCsosn="aplicarCsosn" @aplicarAliqIcms="aplicarAliqIcms"></dialog-detalhar-produto-nota> 
    <dialog-estoque-produto-preco ref="dialogEstoqueProdutoPreco"></dialog-estoque-produto-preco>
  </div>
  
</template>


<script>

import {mapState, mapGetters, mapActions, mapMutations} from "vuex";
import constantes from '@/util/constantes';
import validador from '@/util/validador';
import formatador from '@/util/formatador';
import conversor from '@/util/conversor';
import DialogBuscarParticipante from '@/components/dialogs/DialogBuscarParticipante';
import DialogDetalharProdutoNota from './DialogDetalharProdutoNota';
import DialogEstoqueProdutoPreco from './DialogEstoqueProdutoPreco';

export default {
  
  components:{
    DialogBuscarParticipante,
    DialogDetalharProdutoNota,
    DialogEstoqueProdutoPreco
  },

  data: () => ({
    abaAtiva: 0,
    valid: false,
    validarValor: [v => (v && v.match('^[0-9]+(\,[0-9]{1,2})?$') !== null) || "Valor Inválido"],
    constantes: constantes,
    formatador: formatador,
    passo: 1,
    codigoProduto: '',
    cnpjCpfCliente: '',
    cnpjCpfTransportador: '',
    veiculosTransportador: [],
    veiculoSelecionado: {},
    exibirDialogBuscaParticipante: false,
    headers: [
      { text: 'Código', value: 'codigo', sortable: false },
      { text: 'Seq.', value: 'sequencia', sortable: false },
      { text: 'Descrição', value: 'descricao', sortable: false },
      { text: 'Valor', value: 'valor', align: 'right', sortable: false },
      { text: 'Quantidade', value: 'quantidade', align: 'right', sortable: false },
      { text: 'SubTotal', value: 'subtotal', align: 'right', sortable: false },
      { text: 'Remover', value: 'name', align:'center', sortable: false }
    ],
    headerParcelasPagamento: [
      { text: 'Parcela', value: 'parcela'},
      { text: 'Dias', value: 'dias'},
      { text: 'Vencimento', value: 'vencimento'},
      { text: 'Valor', value: 'valor'}
    ],
    historicoProdutoPreco: [],
    estoqueLocal: [],
    filialSelecionada: '',
    quantidade: '',
    subtotal: '',
    prazo: '',
    parcelas: '',
    cfopPadrao: '5906',
    loading: false
  }),

  computed: {

    ...mapState('notaDeposito', {
      empresa: 'empresa',
      nota: 'notaAtual',
      cliente: 'clienteAtual',
      produto: 'produtoAtual',
      produtosNota: 'produtosNota',
      parcelasNota: 'parcelasNota',
      transportador: 'transportador',
      carregando: 'carregando',
      cfops: 'cfops'
    }),

    ...mapState('notaDeposito', {
      filiais: 'filiais',
      carregandoFiliais: 'carregando'
    }),

    ...mapGetters('notaDeposito', {
      estaEditando: 'estaEditando',
      textoBtnConfirmar: 'textoBtnConfirmar',
      totalVenda: 'totalVenda',
      totalProdutos: 'totalProdutos',
      totalItens: 'totalItens'
    }),
  },

  methods: {

    ...mapActions('notaDeposito', {
      carregarEmpresa: 'carregarEmpresa',
    }),

    ...mapActions('notaDeposito', {
      carregarFiliais: 'carregarFiliais'
    }),

    ...mapMutations('notaDeposito', {
      aplicarCfop: 'setCfopTodosProdutosNota',
      aplicarCsosn: 'setCSOSNTodosProdutosNota',
      aplicarAliqIcms: 'setAliqIcmsTodosProdutosNota'
    }),

    definirFilial(){

      let filial = this.filiais.find(fil => fil._id == this.filialSelecionada);
      if(filial){
        this.$store.commit('notaDeposito/setCliente', filial);
      }
      else{
        this.$store.commit('setMensagemErro', 'Filial não encontrada');
      }
    },

    buscarFilial(item, queryText, itemText){

      const textoFantasia = item.fantasia.toLowerCase();
      const textoCnpj = item.cnpj;
      const textoBusca = queryText.toLowerCase();

      return textoFantasia.indexOf(textoBusca) > -1 || textoCnpj.indexOf(textoBusca) > -1
    },

    removerProdutoNota(item){
      this.$root.$confirmacao.open('Remover', 'Remover item da nota?')
      .then((confirmado) => {
        if(confirmado){
          this.$store.commit('notaDeposito/removerVendido', item);
          this.somarEstoqueLocal(item);
        }
      });
    },

    detalharProduto(index){
      this.$refs.dialogDetalharProduto.abrir(this.produtosNota, index);
    },

    detalharEstoqueProdutoPreco(){
      if(this.historicoProdutoPreco && this.historicoProdutoPreco.length > 0){
        this.$refs.dialogEstoqueProdutoPreco.abrir(this.historicoProdutoPreco);
      }
    },

    produtoSelecionado(produtos) {

      if(produtos && produtos.length > 0){
        
        this.adicionarEstoqueLocal(produtos);

        let produto = produtos[0];
        this.codigoProduto = produto.codigo;
        produto.cfop = this.cfopPadrao;
        this.$store.commit('notaDeposito/setProduto', produto);
        this.quantidade = 1;
        this.subtotal = formatador.formatarValor(produto.valor);
  
        setTimeout(() => {
          let quantidadeProduto = this.$refs.quantidadeProduto;
          quantidadeProduto.focus();
        }, 300);
      }
      else{
        this.$store.commit('setMensagemErro', 'Produto não encontrado ou não possui estoque');
      }
    },

    adicionarEstoqueLocal(produtos){

      // estoque local tem estrutura { codigo: '', produtos: [] }
      if(produtos && produtos.length > 0){
        let produto = produtos[0];
        let existe = this.estoqueLocal.find(estoque => estoque.codigo === produto.codigo);
        if(!existe){
          this.estoqueLocal.push({ codigo: produto.codigo, produtos });
          this.historicoProdutoPreco = produtos;
        }
        else{
          this.historicoProdutoPreco = existe.produtos;
        }
      }
    },

    subtrairEstoqueLocal(produto, quantidade, cfop){

      let produtoEncontrado = this.estoqueLocal.find(estoque => estoque.codigo === produto.codigo);
      if(produtoEncontrado){
        let produtos = produtoEncontrado.produtos;
        let index = 0;
        while(quantidade > 0){
          let prod = produtos[index];
          if(prod.estoque > 0){
            if(prod.estoque <= quantidade){
              quantidade -= prod.estoque;
              this.$store.dispatch('notaDeposito/adicionarProduto', { quantidade: prod.estoque, produto: prod, cfop: cfop });
              prod.estoque = 0;
            }
            else{
              prod.estoque -= quantidade;
              this.$store.dispatch('notaDeposito/adicionarProduto', { quantidade: quantidade, produto: prod, cfop: cfop });
              quantidade = 0;
            }
          }
          index++
        }
      }
    },

    somarEstoqueLocal(item, quantidade){

      let produtoEncontrado = this.estoqueLocal.find(estoque => estoque.codigo == item.codigo);
      if(produtoEncontrado){
        let produtoReposto = produtoEncontrado.produtos.find(prod => prod.codigo === item.codigo && formatador.formatarValor(prod.valor) === item.valor);
        if(produtoReposto){
          produtoReposto.estoque += item.quantidade;
        }
      }
    },

    onFocus(e) {
      e.target.selectionStart = 0;
      e.target.selectionEnd   = e.target.value.length;
    },

    adicionarProduto(){

      let produto = this.$store.state.notaDeposito.produtoAtual;
      
      //verificar disponibilidade do estoque local
      let quantEstoque = this.estoqueLocal.find(prod => prod.codigo === produto.codigo).produtos.reduce((total, p) => total + p.estoque, 0);
      if(quantEstoque >= this.quantidade){
        if(produto.codigo !== '' && this.quantidade !== '' && Number(this.quantidade) > 0 && produto.cfop && produto.cfop !== ''){
          this.subtrairEstoqueLocal(produto, this.quantidade, produto.cfop);
        }
        else{
          this.$store.commit('setMensagemErro', 'Produto Inconsistente');
        }
  
        this.codigoProduto = '';
        this.quantidade = '';
        this.subtotal = '';
        this.$store.commit('notaDeposito/resetProduto');
        this.$refs.codigoProduto.setFocus();
        this.historicoProdutoPreco = [];
      }
      else{
        this.$store.commit('setMensagemErro', `O Produto não possui ${this.quantidade} itens em estoque, existe(m) somente ${quantEstoque} em estoque`)
      }
    },

    async salvar() {
      if (this.$refs.formNota.validate()) {
        try{

          let produtos = this.$store.state.notaDeposito.produtosNota;
          if(produtos.length > 0){
            await this.$store.dispatch('notaDeposito/salvar');
            this.$router.push('/notas');
          }
          else{
            this.$store.commit('setMensagemErro', 'Nenhum item adicionado à nota');
          }
        }
        catch(erro){
          this.$store.commit('setMensagemErro', erro.message);
        }
      }
    },

    cancelar(){
      this.$router.push('/notas');
    },

    definirCfops(){

      let cfop = '';
      
    },

    calcularSubtotal(){
      
      let produto = this.$store.state.notaDeposito.produtoAtual;
      if(produto && this.quantidade !== ''){
        let qtd = conversor.stringParaNumero(this.quantidade);
        let valorProduto = conversor.stringParaNumero(produto.valor);
        this.subtotal = formatador.formatarValor(valorProduto * qtd);
      }
      else{
        this.subtotal = 0;
      }
    },

    recalcularNota(){
      let nota = this.$store.state.nota.notaAtual;
      if(nota.desconto.match('^[0-9]+(\,[0-9]{1,2})?$') && nota.frete.match('^[0-9]+(\,[0-9]{1,2})?$') && nota.seguro.match('^[0-9]+(\,[0-9]{1,2})?$') && nota.outras_despesas.match('^[0-9]+(\,[0-9]{1,2})?$')){
        this.$store.commit('notaDeposito/setTotais');
      }
    },
  },

  async created(){
    
    try{

      this.loading = false;
      let estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;
      if(estaEditando && (!this.nota._id || this.nota._id === '')){
        this.$store.commit('setMensagemErro', 'Selecione uma nota para editar');
        this.$router.push('/notas');
      }
      else{
        await this.carregarEmpresa();
        await this.carregarFiliais();
        if(estaEditando){
          let cliente = this.$store.state.notaDeposito.clienteAtual;
          this.filialSelecionada = cliente._id;
          this.definirFilial();
        }
      }
    }
    catch(erro){
      console.log(erro);
    }
    finally{
      this.loading = false;
    }
  }
};
</script>