<template>
  <v-dialog v-model="dialog" max-width="800px" @keydown.esc="fechar" persistent scrollable>
    <v-card>
      <v-toolbar flat dark color="primary">
        <v-toolbar-title>Histórico de Preço do Produto</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon style="cursor: pointer" @click="fechar()">close</v-icon>
      </v-toolbar>
      <v-divider></v-divider>
      <v-banner single-line v-if="dialog">Produto: {{ historico[0].descricao }}</v-banner>
      <v-divider></v-divider>

      <v-card-text v-if="loading" style="height: 400px" class="pt-1">
        <v-row style="margin-top: 20px">
          <v-col cols="12" class="text-center">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-else style="height: 400px" class="pt-1">
        <v-data-table hide-default-footer :headers="headers" :items="historico" item-key="_id" disable-pagination>
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.codigo }}</td>
              <td>{{props.item.descricao }}</td>
              <td>{{ props.item.unidade }}</td>
              <td>{{ props.item.estoque }}</td>
              <td>{{ props.item.valor | formatarMoeda }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-banner single-line>{{ `Total Estoque: ${ historico && historico.length > 0 ? historico.reduce((total, hist) => total + hist.estoque, 0) : 0}` }} </v-banner>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="fechar()" text>Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapState, mapGetters, mapActions } from "vuex";
import formatador from '@/util/formatador';

export default {

    data(){
      return {
        headers: [
          { text: "Código", value: "codigo", sortable: false },
          { text: "Descrição", value: "descricao", sortable: false },
          { text: "Unid.", value: "unidade", sortable: false },
          { text: "Estoque", value: "estoque", sortable: false },
          { text: "Valor", value: "valor", sortable: false },
        ],
        busca: '',
        historico: null,
        dialog: false,
        loading: false
      }
    },

    computed: {

      
    },

    methods: {

      async abrir(historico) {
        
        try{

          this.loading = true;
          this.dialog = true;

          this.historico = historico;
        }
        catch(erro){
          this.commit('setMensagemErro', erro.message);
        }
        finally{
          this.loading = false;
        }
      },

      fechar(){
        this.dialog = false;
        this.$emit('fechar');
      }
    }
}
</script>